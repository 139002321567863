import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Web Apps',
        description: 'We build custom web apps using the right technology stacks that align with your business objectives.'
    },
    {
        icon: '02',
        title: 'Mobile Apps',
        description: 'Our team of mobile app developers is capable of creating apps for iOS, Android and cross-platform solutions.'
    },
    {
        icon: '03',
        title: 'Graphic Designing',
        description: 'We create fresh, distinctive and identity designs that develop a strong impression for your company and brand recognition.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;