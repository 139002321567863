import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Visitor Management Solution',
        title: 'Guard Application'
    },
    {
        image: 'image-2',
        category: 'Facility Management Solutions',
        title: 'Service App'
    },
    {
        image: 'image-3',
        category: 'Property Management Solutions',
        title: 'Property Admin App'
    },
    {
        image: 'image-4',
        category: 'B2B Sales Solutions',
        title: 'Seedha B2B'
    },
    {
        image: 'image-5',
        category: 'E-Commerce Solutions',
        title: 'La Fiesta'
    },
    {
        image: 'image-6',
        category: 'Mobile Recharge Solutions',
        title: 'Worldrecharge'
    },
    /*{
        image: 'image-7',
        category: 'Conversational Commerce',
        title: 'Zoko'
    },*/
    {
        image: 'image-8',
        category: 'Food Delivery',
        title: 'Deli Deals'
    },/*
    {
        image: 'image-9',
        category: 'Real Estate',
        title: 'The House Monk'
    },*/
    {
        image: 'image-9',
        category: 'Community Application',
        title: 'Papp Resident'
    },/*
    {
        image: 'image-10',
        category: 'Freight Management',
        title: 'Qwickdock'
    }*/
]

const PortfolioListWeb = [
    {
        image: 'image-100',
        category: 'Visitor Management Solution',
        title: 'Security Web'
    },
    {
        image: 'image-200',
        category: 'Facility Management Solutions',
        title: 'Property Web'
    },
    {
        image: 'image-300',
        category: 'Property Management Solutions',
        title: 'Freight Management'
    },
    {
        image: 'image-400',
        category: 'B2B Sales Solutions',
        title: 'Community Web'
    },
    {
        image: 'image-500',
        category: 'E-Commerce Solutions',
        title: 'B2B Seller'
    },
    {
        image: 'image-600',
        category: 'Mobile Recharge Solutions',
        title: 'Service Management'
    },
]


class Portfolio extends Component{
    render(){
        let title = 'Our Works',
        description = 'Some of our recent works.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div> 
                                    <div className="content">
                                        <div className="inner">
                                            {/*<p>{value.category}</p>*/}
                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                            {/*<div className="portfolio-button">
                                                <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div style={{height: 100}}>

                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioListWeb.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div> 
                                    <div className="content">
                                        <div className="inner">
                                            {/*<p>{value.category}</p>*/}
                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                            {/*<div className="portfolio-button">
                                                <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;