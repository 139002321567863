const BlogContent = [
    {
        images: 'conversationalCommerce',
        title: 'Grow Your Business On WhatsApp Non-Stop 24x7.',
        category: 'Conversational Commerce'
    },
    {
        images: 'b2BSales',
        title: 'The Retail & Distribution Chain Interactive Solution.',
        category: 'B2B Sales'
    },

    {
        images: 'foodDelivery',
        title: 'Food Delivery Platform With Location Tracking.',
        category: 'Food Delivery'
    },
    {
        images: 'E-Commerce',
        title: ' Sell A to Z From Your Online Platform.',
        category: 'E-Commerce'
    },
    {
        images: 'realEstate',
        title: 'Property & Land Listing/Buying Platform.',
        category: 'Real Estate'
    },
    {
        images: 'facilityManagement',
        title: 'Micromanage Your Facility Systems 24x7.',
        category: 'Facility Management'
    },
    {
        images: 'visitorManagement',
        title: 'Security Coverage For Your Home & Bussiness.',
        category: 'Visitor Management'
    },
    {
        images: 'propertyManagement',
        title: 'Control Property Level Entities At One Platform.',
        category: 'Property Management'
    },
    {
        images: 'freightManagement',
        title: 'One Click Slot Booking For Your Land & Sea Cargo.',
        category: 'Freight Management'
    },
    {
        images: 'mobileRecharge',
        title: 'Recharge To Your Dear & Near Ones.',
        category: 'Mobile & Card Recharges'
    },
    {
        images: 'Fintech',
        title: 'Instant Money Transfer Solutions.',
        category: 'Fintech'
    },
]

export default BlogContent;